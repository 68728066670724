@import "./Global.scss";

@font-face {
  font-family: "SourceHanSans";
  src: url("./fonts/SourceHanSansHC-VF.otf") format("otf");
}

@font-face {
  font-family: "SourceHanSans";
  src: url("./fonts/SourceHanSansHC-VF.woff2") format("woff2");
}

@font-face {
  font-family: "SourceHanSans";
  src: url("./fonts/SourceHanSansHWHC-VF.otf") format("otf");
}

@font-face {
  font-family: "SourceHanSans";
  src: url("./fonts/SourceHanSansHWHC-VF.woff2") format("woff2");
}

body {
  margin: 0;
  background-color: $primary-background-color;
  color: $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: auto;
  max-width: $max-width;
}

.App {
  margin-bottom: 50px;
  font-family: SourceHanSans, Nunito, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol,
    noto color emoji;

}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  .logo {
    width: 90%;
  }
}
img {
  vertical-align: middle;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
input[type="checkbox"] {
  padding: 0 !important;
}

input[type="checkbox"]:after {
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
  left: -1px;
  background-color: $primary-background-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="checkbox"]:checked:after {
  width: 14px;
  height: 14px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "\2713"; /* Unicode tick character */
  display: inline-block;
  visibility: visible;
  color: $primary-background-color; /* Set the tick color to white */
  font-size: 16px; /* Adjust the size of the tick as needed */
  text-align: center; /* Center the tick within the checkbox */
  line-height: 16px; /* Vertically center the tick */
}
input[type="text"],
input[type="number"],
input[type="phone"],
input[type="email"],
input[type="name"],
input[type="password"],
input[type="date"] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  // border: 1px solid $border-color;
  border: none;
  font-size: $text-m;
  box-sizing: border-box;
  box-shadow: 2px 2px rgb($border-color, 0.2);

  &:focus {
    border: 2px solid $primary-color;
    outline: none;
  }
}

.input-button {
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: $text-m;
  border-radius: 5px;
  border: 1px solid $border-color;
  color: $primary-background-color;
  background-color: $primary-color;
}
.disable-button {
  cursor: not-allowed;
  color: $primary-background-color;
  background-color: $text-color;
}
input {
  padding: 0 !important;
  padding-left: 10px !important;
}

.error-message {
  height: 40px;
  color: $error-color;
  font-size: $text-s;
}

.title {
  text-align: start;
  font-weight: 600;
  font-size: $text-l;
  margin-bottom: 10px;
}

.label {
  font-size: $text-m;
  color: $text-color;
}

.red-start {
  color: $error-color;
}

.button {
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: $text-m;
  border-radius: 5px;
  // border: 1px solid $primary-background-color;
  border: none;
  color: $secondary-color;
  background-color: $primary-color;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-select-container {
  height: 40px;
  border-radius: 5px;
  border: 1px solid $border-color;
  margin: 8px 0;
  .react-select__control {
    height: 40px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      border: 1px solid $border-select-color;
    }
    .react-select__value-container {
      align-content: center;
      height: 40px;
    }
    .react-select__indicators {
      height: 40px;
      padding: 0 0 0 20px;
      align-content: center;
    }
  }
  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px $primary-color;
    border: 1px solid $border-select-color;
  }
  .react-select__control--menu-is-focused {
    box-shadow: 0 0 0 1px $primary-color;
    border: 1px solid $border-select-color;
  }
}

.react-select__menu {
  color: $secondary-color;
  .react-select__menu-list {
    .react-select__option {
      cursor: pointer;
      color: $secondary-color;
      &:hover {
        background-color: $primary-opacity-color;
      }
    }
    .react-select__option--is-selected {
      background-color: $primary-color;
    }
    .react-select__option--is-focused {
      background-color: $primary-opacity-color;
    }
  }
}
