@import "../../Global.scss";
.invitation-code-page-container {
  .banner {
    width: 100%;
  }
  .input-background-container {
    width: 100%;
    background-color: $banner-color;
    .input-background {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      margin: 0 15%;

      .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;
        .login-button {
          width: 160px;
          height: 45px;
          font-size: $text-xl;
          border-radius: 8px;
          text-align: center;
          line-height: 45px;
          background-color: $yellow-color;
          cursor: pointer;
          font-weight: bold;
          margin-right: 10px;
        }
        .register-button {
          width: 160px;
          height: 45px;
          font-size: $text-xl;
          border-radius: 8px;
          text-align: center;
          line-height: 45px;
          background-color: $blue-color;
          cursor: pointer;
          font-weight: bold;
          margin-left: 10px;
        }
      }
      .input-code-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
   
        .input-code {
          text-align: center;
          margin-bottom: 10px;
          border: 1px solid $primary-background-color;
          color: $primary-background-color;
        }
        .input-text {
          text-align: center;
          color: $primary-background-color;
        }

        .input-button {
          display: block;
          margin: auto;
          width: 200px;
        }
        // .enquire-text {
        //   text-align: center;
        //   margin-top: 20px;
        //   text-decoration: underline;
        //   cursor: pointer;
        // }
      }
    }
  }
}
