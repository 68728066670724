@import "../../Global.scss";
.selection-page-container {
  .selection-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .selection-half-container {
      .text {
        font-size: $text-s;
        text-align: center;
        margin-bottom: 20px;
      }
      .select-image {
        .image {
          width: 100%;
          cursor: pointer;
        }
      }
     
    }
  }

  .button-container-display {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 20px;
  }
  .button-container-none {
    display: none;
  }
  .image-container {
    .image {
      width: 100%;
    }
  }
}
