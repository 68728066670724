@import "../../Global.scss";

.information-page-container {
  .information-container {
    margin: 30px 20px;
    .information-text {
      text-align: center;
      font-size: $text-l;
    }
    .enquire-row {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      .label {
        padding-right: 10px;
      }
      .info {
      }
    }
    .point-row {
      margin: 10px 0;
      .point {
        text-align: center;
      }
    }
  }
  .image {
    width: 100%;
    // cursor: pointer;
  }
}
