@import "../../Global.scss";
.form-page-container {
  .form-text {
    font-size: $text-l;
  }
  .selected-image-container {
    .selected-image {
      width: 100%;
    }
  }
  .form-container {
    margin: 30px 10px;
    .input-container {
      margin-bottom: 20px;
      .form-input {
        margin: 10px 0;
      }
      .input-error-message {
        height: 20px;
        font-size: $text-s;
        color: $error-color;
      }
    }
  }
  .disable-button {
    display: none;
  }
}
