@import "../../Global.scss";

.enquire-page-container {
  .enquire-login {
    text-align: center;
    font-size: $text-l;
  }
  .enquire-container {
    margin: 30px 10px;
    .input-container {
      margin-bottom: 20px;
      .form-input {
        margin: 10px 0;
      }
      .input-error-message {
        height: 20px;
        font-size: $text-s;
        color: $error-color;
      }
    }
    .enquire-text {
      text-align: center;
      margin-top: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
