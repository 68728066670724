@import "../../Global.scss";
.question-page-container {
  padding: 0 20px;

  .question-text {
    text-align: center;
    font-size: $text-l;
  }
  .question-image-container {
    margin: 10px 0;
    width: 70px;
    .question-image-label-container {
      display: inline-block;

      .question_image {
        width: 40px;
        height: 40px;
        filter: grayscale(100%);
        transition: filter 0.3s ease-in-out;
        cursor: pointer;
      }
    }
    input[type="checkbox"] {
      display: none;
      &:checked + label .question_image {
        filter: grayscale(0%);
      }
    }
  }
  .question-text-container {
    margin: 40px 10px;

    .question-container {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      justify-content: space-evenly;
      margin-top: 20px;
    }
    .question-container-2 {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-evenly;
      margin-top: 20px;
      .question_image {
        width: 70px;
        height: 70px;
      }
    }
  }
  .tnc-container {
    display: flex;
    align-items: start;
    .tnc {
      text-align: center;
      padding-left: 4px;
    }
  }
}
