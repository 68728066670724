// $primary-color: #878787;
$primary-color: #ffffff;
$primary-opacity-color: #ff590347;
$secondary-color: #000000;
$primary-background-color: #EB5803;
$secondary-background-color: #e5e5e5;
$error-color: rgb(87, 23, 23);
$notice-color: blue;
$border-color: #e5e7eb;
$border-select-color: #d92032;
$yellow-color: #fcc800;
$blue-color:#3abbc8;
$text-color: #ffffff;
$banner-color: #feedcb;
$text-xl: 24px;
$text-l: 18px;
$text-m: 16px;
$text-s: 14px;

$max-width: 600px;
.main-color {
  color: $primary-color;
}
.second-color {
  color: $secondary-color;
}

